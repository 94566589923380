import React, { useEffect, useState } from "react"
import Styles from "./PartOptions.module.scss"
import toProperCase from "../../../../helpers/toProperCase"
import SaveNewPart from "./SaveNewPart/SaveNewPart"
String.prototype.toProperCase = toProperCase
const PartOptions = (props) => {
    const { functions, title, salesPer, price, imgurl, year, make, model, makes } = props
    const [saveNewPartEnabled, toggleSaveNewPart] = useState(false)
    const { togglePartOptions, savePart, searchLocation, searchCompatibility, getModels, getPartTitlesUsingCode } = functions
    const [partTitles, setTitles] = useState([])
    useEffect(() => {

        let outterWrap = document.querySelector("#outter")
        outterWrap.style.top = `${window.scrollY}px`;

        async function getAndSetTitles() {
            const titles = await getPartTitlesUsingCode(`${year} ${make} ${model}`);
            setTitles(titles)
        }
        getAndSetTitles();

    }, [])

    const outterClick = (e) => {
        const { id } = e.target
        if (id == "outter") togglePartOptions(false)
    }

    const locationSearch = () => {
        searchLocation()
        togglePartOptions(false)
    }

    let content = (
        <div onMouseDown={outterClick} id="outter" className={Styles['outterWrap']}>

            {
                saveNewPartEnabled ?
                    <SaveNewPart makes={makes} title={title} imgurl={imgurl} price={price} salesPer={salesPer}
                        togglePartOptions={togglePartOptions} savePart={savePart}
                        year={year} make={make} model={model} getModels={getModels} searchCompatibility={searchCompatibility}
                        locationSearch={locationSearch} />
                    :
                    <div className={Styles['innerWrap']}>
                        <p onClick={() => toggleSaveNewPart(true)}>Save Part</p>
                        <p onClick={searchCompatibility}>Check Compatibility</p>
                        <p onClick={locationSearch}>Location</p>
                        <select>
                            <option key="saved" value="" disabled>Saved Parts</option>

                            {partTitles.map(x => <option key={x}>{x}</option>)}
                        </select>
                    </div>
            }

        </div>
    )
    return content
}



export default PartOptions